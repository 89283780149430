import 'babel-polyfill';
import Vue from 'vue';
import ElementUI from 'element-ui';
import App from './App.vue';
import router from './router';
import store from './store';
// 引入自定义的全局样式文件
import './styles/reset.css';

Vue.use(ElementUI);

Vue.directive('title', {
  inserted(el) {
    document.title = el.dataset.title;
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
